import Vue from 'vue'
import App from './App.vue'
import router from "./router.js"
import store from "./store/index.js"
import _axios from "./plugins/axios.js"
import slideVerify from "./plugins/slideVerify.js"
import Vcode from "./plugins/puzzleVcode.js";
import happyScroll from "./plugins/happyScroll.js"

import './plugins/element.js'
// import './plugins/kindEditor.js'
import './plugins/avueUeditor.js'

Vue.config.productionTip = false

// Vue.prototype.$store = store
Vue.prototype.$axios = _axios


Vue.component('Vcode',Vcode)


export const $vue = new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app')
Vue.prototype.$vue = $vue