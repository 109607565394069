//页面配置相关状态管理
import {getStore,setStore} from "../../assets/js/utils.js"
const config = {
	namespaced: true,
	state: {
		collapse:getStore("collapse")=='false'? false : getStore("collapse")=='true'? true:true || true,//控制折叠菜单
		currentRouterItem:(getStore("currentRouterItem") !="undefined" && typeof getStore("currentRouterItem") === 'string') ? JSON.parse(getStore("currentRouterItem")) : getStore("currentRouterItem") || {},//当前路由页面菜单item
		currentRouterId: getStore("currentRouterId") || "",//当前路由页面菜单id
		currentRouterTitle:getStore("currentRouterTitle") || "列表" ,//当前路由页面顶部导航栏标题
		currentNavigater:(getStore("currentNavigater") !="undefined" && typeof getStore("currentNavigater") === 'string') ? JSON.parse(getStore("currentNavigater")) : getStore("currentNavigater") || [{name:"主页",uri:"/index"}],//面包屑导航
		allNavigater:(getStore("allNavigater") !="undefined" && typeof getStore("allNavigater") === 'string') ? JSON.parse(getStore("allNavigater")) : getStore("allNavigater") || [[{name:"主页",uri:"/index"}]]
	},
	mutations: {
		config_setCollapse(state, collapse) {
			state.collapse = collapse;
			setStore('collapse',collapse)
		},
		config_setCurrentRouterItem(state, currentRouterItem) {
			state.currentRouterItem = currentRouterItem;
			setStore('currentRouterItem',currentRouterItem)
		},
		config_setCurrentRouterTitle(state, currentRouterTitle) {
			state.currentRouterTitle = currentRouterTitle;
			setStore('currentRouterTitle',currentRouterTitle)
		},
		config_setCurrentRouterId(state, currentRouterId) {
			state.currentRouterId = currentRouterId;
			setStore('currentRouterId',currentRouterId)
		},
		config_setCurrentNavigater(state, currentNavigater) {
			state.currentNavigater = currentNavigater
			setStore('currentNavigater',currentNavigater)
		},
		config_setAllNavigater(state, allNavigater) {
			state.allNavigater = allNavigater
			setStore('allNavigater',allNavigater)
		}
	}
}

export default config