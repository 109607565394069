import Vue from 'vue'
import Vuex from 'vuex'
import user from "./modules/user.js"
import config from "./modules/config.js"
import right from "./modules/right.js"
import tags from "./modules/tags.js"

Vue.use(Vuex)

const store = new Vuex.Store({
	modules: {
	  user,
	  config,
	  right,
	  tags
	}
})

export default store