"use strict";

import Vue from 'vue';
import axios from "axios";
import {getToken,getStore} from "../assets/js/utils.js"
import {$vue} from "../main.js"

const baseUrl = process.env.VUE_APP_API=="/"?'/api':process.env.VUE_APP_API

//axios请求
const _axios = (methods,url, data, isToken) => {
	let token = ""
	let tokenType = ""
	if(isToken){
		token = getToken("token")
		tokenType = getStore("tokenType")
	}
	// let isLocal = url.search("http");//自定义请求地址
	let httpDefaultOpts = {
		// url: "/pangguhaoshop"+url,
		// url: "/api"+url,
		url:url,
		data: data,
		baseURL:baseUrl,
		method: methods,
		xhrFields: {
			withCredentials: true
		},
		headers: {
			'Content-Type': 'application/json;charset=UTF-8',
		},
		dataType: 'json',
	}
	// if (isToken && token) httpDefaultOpts.header.Authorization = tokenType+" "+token
	if (isToken && token) httpDefaultOpts.headers[tokenType] =token
	let promise = new Promise(function(resolve, reject) {
		axios(httpDefaultOpts).then(
			(res) => {
				const {data,data:{code,message}} = res
				if(code && code==200){
					if(data.data)
						resolve(data.data);
					else
						resolve(null);
				}else{
					if(code==401){
						$vue.$message.error("token过期，请重新登陆！");
						$vue.$store.commit("user/user_logout")
						setTimeout(_ => {
							$vue.$router.push('/login')
						}, 500)
					}else if(code==30001){
						resolve({code:30001,msg:message});
					}else{
						return $vue.$message.error(message || "未知错误");
					}
				}
			}
		).catch(
			(response) => {
				console.log(response)
				if(response.response){
					switch (response.response.status){
						case 401:
							$vue.$message.error("token过期，请重新登陆！");
							$vue.$store.commit("user/user_logout")
							setTimeout(_ => {
								$vue.$router.push('/login')
							}, 500)
							break;
						case 403:
						case 404:
							// $vue.$message.error("网络错误，请检查网络后重试！");
							$vue.$notify.warning("找不到啦！");
							$vue.$router.push('/404')
							break;
						case 500:
							// $vue.$message.error("网络错误，请检查网络后重试！");
							$vue.$notify.warning("出错啦，无法连接服务！");
							$vue.$router.push('/404')
							break;
						default:
							// $vue.$message.error("系统错误,请联系管理员!");
							$vue.$notify.warning("出错啦！");
							// $vue.$router.push('/404')
							break;
					}
				}else{
					// $vue.$message.error("系统错误,请联系管理员!");
					$vue.$notify.warning("网络出错啦！");
					$vue.$router.push('/login')
				}
				reject(response);
			}
		)
	})
	return promise
};

export default _axios;