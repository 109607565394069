import Vue from 'vue'
import Router from 'vue-router'
import store from './store'

Vue.use(Router)

const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

const router = new Router({
	// mode: 'history',
	routes: [{
			path: '/',
			redirect: '/index',
		},
		{
			path: '/',
			component: () => import('./pages/home.vue'),
			children: [{
					path: '/index',
					name: 'index',
					meta: {
						title: '首页'
					},
					component: () => import('./pages/index.vue')
				},
				{//包含子菜单，同一分类下的路由,系统管理
					path: '/system/:id',//id用于命名自定义js文件名
					name: 'system',
					meta: {
						title: '系统管理'
					},
					component: () => import('./components/elementListBase.vue')
				},
				//门店管理
				{
					path:"/store/:id",
					name:'store',
					meta:{
						title: '门店管理' //
					},
					component: () => import('./components/elementListBase.vue')
				},
				//会员管理
				{
					path:"/userManager/:id",
					name:'userManager',
					meta:{
						title: '会员管理' //
					},
					component: () => import('./components/elementListBase.vue')
				},
				//订单管理
				{
					path:"/orderManager/:id",
					name:'orderManager',
					meta:{
						title: '订单管理' //
					},
					component: () => import('./components/elementListBase.vue')
				},
				//支付管理
				{
					path:"/payManager/:id",
					name:'payManager',
					meta:{
						title: '支付管理' //
					},
					component: () => import('./components/elementListBase.vue')
				},
				//优惠活动
				{
					path:"/couponActivity/:id",
					name:'couponActivity',
					meta:{
						title: '优惠活动' //
					},
					component: () => import('./components/elementListBase.vue')
				},
				//物流管理
				{
					path: '/deliveryManager/:id',
					name: 'deliveryManager',
					meta:{
						title: '物流管理' //
					},
					component: () => import('./components/elementListBase.vue')
				},
				{//权限
					path: '/Right',
					name: 'Right',
					meta: {
						title: '权限'
					},
					component: () => import('./pages/right.vue')
				},
				{//单一路由
					path: '/Categories',
					name: 'Categories',//用于命名自定义js文件名
					meta: {
						title: ''
					},
					component: () => import('./components/elementListBase.vue')
				}
			]
		},
		{
			path: '/login',
			name: 'login',
			meta:{
				title: '盘贾号' //
			},
			component: () => import('./pages/login.vue')
		},
		{
			path: '*',
			name: '未找到',
			meta:{
				title: '盘贾号' 
			},
			component: () => import('./pages/404.vue')
		}
	]
})

//全局路由守卫
router.beforeEach((to, from, next) => {
  console.log('跳转到:', to.fullPath);
	if (to.meta.title) {
		document.title = to.meta.title
	}
	const isLogin = store.state.user.isLogin
	//如果没登录,都导向登录页
	if (!isLogin) {
		if (to.path !== '/login') {
			next({ path: '/login' })
		}
		else {
			next();
		}
	}
	else {
		next();
	}
})

export default router