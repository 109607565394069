import {getToken,setToken,getStore,setStore} from "../../assets/js/utils.js"
const user = {
	namespaced: true,
	state: {
		isLogin:getStore("isLogin") || false, // 是否登录
		userData: (getStore("userData") !="undefined" && typeof getStore("userData") === 'string') ? JSON.parse(getStore("userData")) : getStore("userData") || {}, // 用户信息
		// sysRoles:(getStore("userData") !="undefined" && typeof getStore("userData") === 'string') ? JSON.parse(getStore("userData")) : getStore("userData") || {}, // 信息
		token:getToken() || '', // token
		tokenType:getStore("tokenType") || '', // token
	},
	mutations: {
		user_setUserData(state, userData) {
			state.userData = userData
			setStore("userData",userData)
		},
		// user_setUserSysRoles(state, userData){
		// 	state.userData = userData
		// 	setStore("userData",userData)
		// },
		user_setToken(state, token) {
			state.token = token
			setToken(token)
		},
		user_tokenType(state, tokenType) {
			state.tokenType = tokenType
			setStore("tokenType",tokenType)
		},
		user_setLogin(state, isLogin) {
			state.isLogin = isLogin
			setStore("isLogin",isLogin)
		},
		user_logout(state) {
			state.isLogin = false
			state.userData = {}
			state.token = ''
			state.tokenType = ''
			setStore("userData",{})
			setToken("")
			setStore("tokenType","")
			setStore("isLogin",false)
		}
	}
}

export default user