<template>
	<div id="app">
		<router-view/>
	</div>
</template>

<script>

export default {
	name: 'app'
};
</script>

<style lang="scss">
    body{
		font-family: "microsoft yahei,arial, helvetica, sans-serif"
	}
	//表单弹窗底部确定取消按钮样式
	.btnArea{
		text-align: right;
		margin-top: 15px;
		button:first-child{
			margin-right: 10px;
		}
	}
	//table表头错位修正
	body .el-table th.gutter{
		display: table-cell!important;
	}
	body .el-table colgroup.gutter{
		display: table-cell!important;
	}
	//对话框宽度
	// .el-dialog{
	// 	width: 44% !important;
	// }
	.el-dialog__body{
		box-sizing: border-box !important;
	}
	#generalList .el-dialog__wrapper {
		overflow: hidden !important;
		bottom:30px !important;
		top:-30px !important;
	}
	#generalList .el-dialog__body{
		// padding-right: 40px !important;
		padding-top: 10px !important;
		padding-bottom: 15px !important;
	}
	#generalList .dialog-box{
		border: 1px solid #ebebeb;
		padding: 20px 20px 10px 0; 
		max-height: 440px;
		overflow: auto;
	}
	//富文本
	.ke-container.ke-container-default {
		width: auto !important;
		.ke-edit {
			min-height: 300px !important;
		}
		.ke-edit-iframe{
			height: 100% !important;
		}
	}
	//搜索框
	//分页
	.paging{
		float: right;
		margin-right: 60px;
		margin-top: 6px;
	}
	//上传预览遮罩
	// .dialog-box .v-modal{
	// 	z-index: 0 !important;
	// }
	//上传框大小
	.el-upload-list--picture-card .el-upload-list__item,
	.el-upload--picture-card{
		width: 120px !important;
		height: 120px !important;
	}
	.el-upload--picture-card{
		line-height: 126px;
	}
	
	//多条件查询头部样式
	.conditionQuery-bar{
		border: 1px solid #EBEEF5;
		padding: 0 6px;
	}
	//顶部搜索新建返回区域样式
	.search-new-back-bar{
		display: flex;
		align-items: center;
		margin: 18px 0 20px 0; 
	}
	//新增一个自定义element ui button按钮样式
	.el-button.el-button--custom{
		background-color: #536d91;
		color: #f0f0f0;
	}
	.el-button.el-button--custom.is-plain{
		color: #536d91;
		background-color: #e8f4ff;
		border-color: #a7badc;
	}
	.el-button.el-button--custom.is-plain:hover{
		background-color: #536d91;
		color: #fff;
	}
	//圆形按钮大小
	.toolBar{
		.el-button.is-circle{
			height: 32px;
			width: 32px;
		}
	}
</style>
