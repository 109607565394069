import {getStore,setStore} from "../../assets/js/utils.js"
const right = {
	namespaced: true,
	state: {
		btnList: (getStore("btnList")!="undefined" && typeof getStore("btnList") === 'string') ? JSON.parse(getStore("btnList")) : getStore("btnList") || [], // 操作按钮
		menuList: (getStore("menuList") !="undefined" && typeof getStore("menuList") === 'string') ? JSON.parse(getStore("menuList")) : getStore("menuList") || [] // 菜单
	},
	mutations: {
		right_setBtnList(state, btnList) {
			state.btnList = btnList
			setStore("btnList",btnList)
		},
		right_setMenuList(state, menuList) {
			state.menuList = menuList
			setStore("menuList",menuList)
		}
	}
}

export default right
