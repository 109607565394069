//页面配置相关状态管理
import {getStore,setStore} from "../../assets/js/utils.js"
const tags = {
	namespaced: true,
	state: {
		tagsArr: (getStore("tagsArr")!="undefined" && typeof getStore("tagsArr") === 'string') ? JSON.parse(getStore("tagsArr")) : getStore("tagsArr") || [{name:"主页",uri:"/index"}]
	},
	mutations: {
		tags_addTagsArr(state, tags) {
			if (state.tagsArr.some(v => v.uri === tags.uri)) return
			state.tagsArr.push(tags)
			setStore('tagsArr',state.tagsArr)
		},
		tags_delTagsArr(state, tags) {
			// 数组entries()返回一个数组的迭代对象
			for (const [i, v] of state.tagsArr.entries()) {
			  if (v.uri === tags.uri) {
			    state.tagsArr.splice(i, 1)
				console.log(state.tagsArr)
				setStore("tagsArr",state.tagsArr)
			    break
			  }
			}
		},
		tags_delAllTagsArr(state, tags) {
			state.currentRouterTitle = currentRouterTitle;
			setStore('currentRouterTitle',currentRouterTitle)
		}
	}
}

export default tags